import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Table, Card, Container, Row, Col } from "react-bootstrap";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
  <Row className="px-4" mdxType="Row">
   <Col xs={12} sm={12} lg={7} mdxType="Col">
          <p>{`This Privacy statement explains what personal data is collected by the BY-COVID website and why.`}</p>
          <p>{`The data is collected based on anonymised IP addresses. Each computer is assigned an Internet Protocol (IP) address online, which looks like: 123.456.78.91. This address can change each time you go on the web, or it can be fixed. The number can give information about where you are in the world. This is much more difficult if the last few digits of the address are removed when the data is collected, which happens in our case. We are unable to identify anyone's location other than a broad region of a country.`}</p>
          <h2>{`What data we collect`}</h2>
          <p>{`We collect anonymised IP addresses so that we can record the following activity from each IP address:`}</p>
          <ul>
            <li parentName="ul">{`which pages people have visited`}</li>
            <li parentName="ul">{`how long people have spent on each page`}</li>
            <li parentName="ul">{`which browsers and devices people have used to view the site`}</li>
            <li parentName="ul">{`which country people view the site from.`}</li>
          </ul>
          <p>{`The information is collected in an aggregated form. We cannot identify you from the information gathered, or track your web browsing outside of this site.`}</p>
          <h2>{`How we collect the data`}</h2>
          <p>{`We use `}<a parentName="p" {...{
              "href": "https://analytics.withgoogle.com/"
            }}>{`Google Analytics`}</a>{` to collect this data. When you visit this site the Google Analytics program puts a small file called a cookie in your browser. Cookies are widely used to store preferences and to enable shopping carts and log-in areas on websites.`}</p>
          <h2>{`Why we collect the data`}</h2>
          <p>{`We collect the data so that we can then generate reports about the site for our funders. We also collect the date to help us improve the site, and ensure we design the site for the browsers and devices that most people are using.`}</p>
          <h2>{`Who has access to the data`}</h2>
          <p>{`The data collected from you visit to the site is sent to Google but aggregated and anonymised. We do not have access to personally identifiable information, but Google records and stores your anonymised IP address in order to generate the site statistics.`}</p>
          <p>{`See `}<a parentName="p" {...{
              "href": "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
            }}>{`Google Analytics cookies information`}</a>{` and `}<a parentName="p" {...{
              "href": "https://support.google.com/analytics/answer/6004245?hl=en"
            }}>{`Google’s Safeguarding your data article`}</a>{`.`}</p>
          <h2>{`If you have concerns about your data`}</h2>
          <ul>
            <li parentName="ul">{`Remove the cookie manually. See the `}<a parentName="li" {...{
                "href": "https://us.norton.com/internetsecurity-privacy-how-to-clear-cookies.html"
              }}>{`Norton Security website`}</a>{` for detailed instructions.`}</li>
            <li parentName="ul">{`Use a browser extension to stop the cookie, such as `}<a parentName="li" {...{
                "href": "https://tools.google.com/dlpage/gaoptout"
              }}>{`Google’s Opt-out Browser Add-on`}</a>{`.`}</li>
          </ul>
          <h2>{`Contact`}</h2>
          <p>{`If you have any informal questions or concerns about privacy on the website, including exercising you rights to view or amend personal data you think we may have, feel free to email `}<a parentName="p" {...{
              "href": "mailto:webmaster@elixir-europe.org"
            }}>{`webmaster@elixir-europe.org`}</a>{`.`}</p>
          <h3>{`Data controller for the website`}</h3>
          <p>{`The website is developed by the ELIXIR Hub.`}</p>
          <p>{`Niklas Blomberg,`}<br />{`
ELIXIR Director`}<br />{`
Email: `}<a parentName="p" {...{
              "href": "mailto:data-controller@elixir-europe.org"
            }}>{`data-controller@elixir-europe.org`}</a></p>
          <p>{`EMBL-EBI,
Wellcome Genome Campus,
CB10 1SD Hinxton,
Cambridgeshire, UK`}</p>
        </Col>
      </Row>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      